/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   App.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React, { useState, useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import IdeaMapper from "./wasm.jsx";
import { isAndroid, isFirefox, isIE, isLegacyEdge } from "mobile-device-detect";
import reactAppVars from "./resources/ReactAppVariables.json";
import verifySessionToken from "./resources/verifySessionToken";
/*IMPORT_PLACEHOLDER_START*/
import QtWasmLoaderAdmin from "./admin-wasm.jsx";
import LoginComponent from "./components/Login/LoginComponent";
import { GoogleOAuthProvider } from "@react-oauth/google";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
Amplify.configure(awsExports);
/*IMPORT_PLACEHOLDER_END*/
const tabUID = window.generateUniqueID(); // Unique identifier for this tab
// ----------------------------------------------------------------------------
// Main App Class
const App = () => {
  const [showSignInPage, setShowSignInPage] = useState(true);
  const [signInStatusApp, setSignInStatusApp] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(false);
  const [addRemoveListener, setAddRemoveListener] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [loadFurther, setLoadFurther] = useState(true);
  const eventListeners = {};
  const channel = new BroadcastChannel("IdeaMapper_BCC"); // Create a new Broadcast Channel with the given name
  // Better to use a variable than useState ->
  //    1. Have to add dependencies to useEffect which causes more re-renders.
  let wasmVersionApp = undefined;
  let viewerMode = undefined;

  // ----------------------------------------------------------------------------
  useEffect(() => {
    // Set login pages height as global css var
    const r = document.querySelector(":root");
    r.style.setProperty("myHeight", "calc(max-content + 5%)");

    extractURLParams();
    document.addEventListener("focusin", appPassToWASM);

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    const canLoadConfig = checkDeviceCompatibility();
    if (canLoadConfig) startup();
    if (viewerMode === true) {
      window.saveDataForWASM(
        "viewerMode",
        "viewerModeIdTokenString",
        "viewerModeAccessTokenString",
        "viewerModeUserServiceToken"
      );
      toggleShowSignInPage(false);
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      document.removeEventListener("focusin", appPassToWASM);
      // Any additional cleanup
    };
  }, []);

  // ----------------------------------------------------------------------------
  useEffect(() => {
    window.setBrowserOfflineOnline(isOnline);

    if (!addRemoveListener) {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [isOnline, addRemoveListener]);

  // ----------------------------------------------------------------------------
  useEffect(() => {
    const canvas = document.getElementById("screen");
    if (canvas) {
      const handleClick = (event) => {
        canvas.focus();
        event.target.style.background = "";
      };

      const handleBlur = () => {
        canvas.addEventListener("click", handleClick);
      };

      canvas.addEventListener("blur", handleBlur);

      return () => {
        canvas.removeEventListener("blur", handleBlur);
        canvas.removeEventListener("click", handleClick);
      };
    }
  }, []);

  // ---------------------------------------------------------------------------
  // Create a new Broadcast Channel
  function startBCReceiver() {
    channel.addEventListener("message", (event) => {
      const { senderUID, message } = event.data;
      if (senderUID !== tabUID) {
        // Ignore self-sent messages
        console.log(`Received message from ${senderUID}:`, message);

        // Send a response back to the sender
        channel.postMessage({
          senderUID: tabUID, // This tab's UID
          recipientUID: senderUID, // Direct the message to the sender
          message: "Message received in tab 1! You can now close!",
        });
        document.getElementById("root").focus();
        window.focus();
        window.passOpenLinkToWASM(message);
      }
    });
  }
  window.startBCReceiver = startBCReceiver;

  // ----------------------------------------------------------------------------
  const checkDeviceCompatibility = () => {
    let canLoadConfig = true;

    // Check if the user is using a mobile device.
    //if (isMobile) {
    if (isFirefox) {
      // Block for mobile Firefox due to instability.
      canLoadConfig = false;
    } else if (isAndroid) {
      // Inform users about potential issues on Android devices.
      window.alert(
        "Thank you for trying ideamapper on your mobile device or tablet. Please note there may be issues..."
      );
    }

    // Check if the user is using a supported browser.
    if (canLoadConfig && (isIE || isLegacyEdge)) {
      canLoadConfig = false;
      window.alert(
        "Please switch browsers. Your browser is currently not supported."
      );
    }

    return canLoadConfig;
  };

  // ----------------------------------------------------------------------------
  const startup = async () => {
    // Set page title from Application Variables
    document.title = reactAppVars.PAGE_TITLE;
    // Get data from config file

    try {
      const {
        isOnline,
        offlineMessage,
        offlineUntil,
        userCloudAPI,
        wasmLocation,
        wasmVersion,
      } = await window.getConfigs(reactAppVars.MAIN_CONFIG_URL);

      // First check if cloud is online
      if (!isOnline) {
        //If offline stop page loading and give user the message.
        cloudIsOffline(offlineUntil, offlineMessage);
        return;
      } else if (wasmLocation !== undefined && userCloudAPI !== undefined) {
        // Set variables for the app
        sessionStorage.setItem("wasmLocation", wasmLocation);
        if (
          wasmVersionApp !== "" &&
          wasmVersionApp !== null &&
          wasmVersionApp !== undefined
        ) {
          window.wasmVersion = wasmVersionApp;
          sessionStorage.setItem("wasmVersion", wasmVersionApp);
        } else {
          sessionStorage.setItem("wasmVersion", wasmVersion);
        }
        sessionStorage.setItem("userCloudURL", userCloudAPI);
        setConfigLoaded(true);
      }
    } catch (error) {
      console.error("Error during startup:", error);
      // Handle any errors that occur during startup
    }
  };

  // ----------------------------------------------------------------------------
  const toggleShowSignInPage = (truefalse) => {
    setShowSignInPage(truefalse);
    setSignInStatusApp(!truefalse);
  };

  // ----------------------------------------------------------------------------
  //Function to pass clipboard to WASM when tab gets focus
  const appPassToWASM = () => {
    window.getClipboardToWASM();
  };

  // ----------------------------------------------------------------------------
  // Function to extract and pass params to be saved then later passed to the
  // wasm module
  const extractURLParams = () => {
    const params = new URLSearchParams(window.location.search);
    if (params && params.toString() !== "" && params.size !== 0) {
      window.saveParamForWASM(params);
      changeHistoryAndURL();
    }
  };

  // ----------------------------------------------------------------------------
  //If scheduled offline stop page loading and give user the message.
  const cloudIsOffline = (offlineUntil, offlineMessage) => {
    window.stop(); // Note: window.stop() stops the loading of the current resource, use it cautiously.
    alert(
      `The cloud is currently offline, we apologize for any inconvenience! The cloud will be offline until ${offlineUntil}.\nAdditional message: ${offlineMessage}.`
    );
  };

  // ----------------------------------------------------------------------------
  const handleBeforeUnload = (event) => {
    //event.preventDefault();
    event.returnValue = "Are you sure you want to leave this page?";
  };
  window.handleBeforeUnloadWindow = handleBeforeUnload;
  // ----------------------------------------------------------------------------
  const handleRemovePageLeaveEvent = () => {
    if (eventListeners["beforeunload"]) {
      const index = eventListeners["beforeunload"].indexOf(handleBeforeUnload);
      if (index !== -1) {
        eventListeners["beforeunload"].splice(index, 1);
      }
    }
    window.removeEventListener("beforeunload", handleBeforeUnload);
    setAddRemoveListener(false);
  };
  window.removePageLeaveEventWindow = handleRemovePageLeaveEvent;

  // ----------------------------------------------------------------------------
  const handleAddPageLeaveEvent = () => {
    if (!eventListeners["beforeunload"]) {
      eventListeners["beforeunload"] = [];
      eventListeners["beforeunload"].push(handleBeforeUnload);
      window.addEventListener("beforeunload", handleBeforeUnload);
      setAddRemoveListener(true);
    }
  };
  window.addPageLeaveEventWindow = handleAddPageLeaveEvent;
  // ----------------------------------------------------------------------------

  // Function to check if a listener exists
  function hasWindowEventListener(type, listener) {
    return (
      eventListeners["beforeunload"] &&
      eventListeners["beforeunload"].includes(listener)
    );
  }
  window.hasWindowEventListenerWindow = hasWindowEventListener;
  // ----------------------------------------------------------------------------
  // Change the history and get rid of any additions to url
  const changeHistoryAndURL = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("wasmVersion")) {
      wasmVersionApp = params.get("wasmVersion");
    }
    if (params.has("viewerMode")) {
      viewerMode = true;
      sessionStorage.setItem("viewerMode", true);
    }
    if (params.has("openFile")) {
      var link = params.get("openFile");
      const encodedFileUrl = link.replace(/ /g, "%20");
      channel.postMessage({
        senderUID: tabUID,
        message: `${encodedFileUrl}`,
      });
      channel.addEventListener("message", (event) => {
        const { senderUID, recipientUID, message } = event.data;
        if (senderUID !== tabUID) {
          console.log(
            `senderUID: ${senderUID}, recipientUID: ${recipientUID}, message: ${message}`
          );
          if (message.includes(`close`)) {
            setLoadFurther(false);
          }
        }
      });
    }
    if (
      !params.has("code") &&
      !params.has("wasmVersion") &&
      !params.has("viewerMode") &&
      !params.has("openFile")
    ) {
      const urlWithoutSearchParams =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.replaceState({}, "", urlWithoutSearchParams);
      window.location.hash = "";
    }
  };

  const ideaMapperComponent = useMemo(
    () => (
      <IdeaMapper
        wasmsrcurl={reactAppVars.WASM_SRC_URL}
        wasmemscriptenstring={reactAppVars.WASM_EMSCRIPTEN_STRING}
      />
    ),
    [reactAppVars.WASM_SRC_URL, reactAppVars.WASM_EMSCRIPTEN_STRING]
  );

  // ------------------------------------------------------------------------
  // Function to check if there is a token in the session storage and send it
  // to user service for a verification check
  const checkExistingToken = async (accessToken) => {
    if (accessToken && accessToken !== null && accessToken !== undefined) {
      const verified = await verifySessionToken(accessToken);
      if (verified === true) {
        return true;
      } else {
        sessionStorage.removeItem("userServiceAccessToken");
        return false;
      }
    } else {
      sessionStorage.removeItem("userServiceAccessToken");
      return false;
    }
  };
  // ----------------------------------------------------------------------------
  // Main render
  return (
    <div className="App">
      {/*PLACEHOLDER_ROUTES_START*/}
{showSignInPage && configLoaded && (
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <Routes>
      <Route
        path="/"
        element={
          <LoginComponent
            signInStatusApp={signInStatusApp}
            showSignInPage={showSignInPage}
            setShowSignInPage={toggleShowSignInPage}
            userCloudAPI={sessionStorage.getItem("userCloudAPI")}
            configLoaded={configLoaded}
            loadFurther={loadFurther}
            removePageLeaveEvent={handleRemovePageLeaveEvent}
            changeHistoryAndURL={changeHistoryAndURL}
            checkExistingToken={checkExistingToken}
          />
        }
      />
      <Route
        path="/admin"
        element={
          <LoginComponent
            signInStatusApp={signInStatusApp}
            showSignInPage={showSignInPage}
            setShowSignInPage={toggleShowSignInPage}
            userCloudAPI={sessionStorage.getItem("userCloudAPI")}
            configLoaded={configLoaded}
            removePageLeaveEvent={handleRemovePageLeaveEvent}
            checkExistingToken={checkExistingToken}
          />
        }
      >
        {signInStatusApp && configLoaded && (
          <Route
            index
            element={
              <QtWasmLoaderAdmin
                wasmadminsrcurl={reactAppVars.WASM_ADMIN_SRC_URL}
                wasmadminemscriptenstring={
                  reactAppVars.WASM_ADMIN_EMSCRIPTEN_STRING
                }
              />
            }
          />
        )}
      </Route>
    </Routes>
  </GoogleOAuthProvider>
)}

{/*PLACEHOLDER_ROUTES_END*/}
      {signInStatusApp && configLoaded && ideaMapperComponent}
    </div>
  );
};

export default React.memo(App);
// ----------------------------------------------------------------------------
