/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:e23507bf-d1a5-418f-85bd-c15a726a4806",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_3E3hB30nu",
  aws_user_pools_web_client_id: "5v7jvpcu7s3c7gqbrod2p2rfms",
  oauth: {
    domain: "auth.webapp.ideamapper.com",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "https://webapp.ideamapper.com/",
    redirectSignOut: "https://webapp.ideamapper.com/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL", "FAMILY_NAME", "GIVEN_NAME"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
