/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   exchange.js
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import axios from "axios";

// ------------------------------------------------------------------------
// Function to send a token provided to user service for verification.
export default async function verifySessionToken(accessToken) {
  let url =
    sessionStorage.getItem("userCloudURL").toString() + "verifysessiontoken";

  let config = {
    method: "post",
    url: url,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };

  try {
    const verified = await axios(config);
    if (verified.data.success === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response);
      console.log(error.response.data.Error);
      console.log(error.response.status);
      console.log(error.response.headers);
      return error.response.data.Error;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      return false;
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    // Handle any other errors that may occur during the axios request or destructuring process
    console.error(error.data);
    return false;
  }
}

// ------------------------------------------------------------------------
