/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   ClassLinkLogIn.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import "./GoogleCustomLoginButton.css";

const GoogleLoginButton = (props) => {
  const loginToGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      props.onGoogleSuccess(tokenResponse);
    },
    flow: "implicit",
    prompt: "",
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.appdata https://www.googleapis.com/auth/drive.appfolder https://www.googleapis.com/auth/drive.install https://www.googleapis.com/auth/drive.file",
  });
  return (
    <button
      type="button"
      className="googleCustomLoginButton"
      onClick={() => loginToGoogle()}
      // onClick={() => window.onClickHandle()}
    >
      Sign in with Google
    </button>
  );
};

export default GoogleLoginButton;
