export const ageEnum = {
  valid: "valid",
  invalid: "invalid",
  unknown: "unknown",
};

export const supportLinks = {
  termsOfService:
    "https://www.ideamapper.com/wp-content/uploads/2023/06/Ideamapper-Inc-Terms-of-Service.pdf",
  cookiePolicy: "https://www.ideamapper.com/cookies-policy/",
  gdpr: "https://www.ideamapper.com/gdpr-policy/",
  privacyPolicy:
    "https://www.ideamapper.com/wp-content/uploads/2023/10/Ideamapper-Inc-Privacy-Policy-2023.pdf",
  support: "https://support.ideamapper.com/portal/en/home",
};
