// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   wasm.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------
import React, { useEffect, useRef } from "react";

const IdeaMapper = ({ wasmsrcurl, wasmemscriptenstring }) => {
  const scriptLoaded = useRef(false);
  useEffect(() => {
    if (scriptLoaded.current) {
      return;
    }
    const script = document.createElement("script");
    var scriptSRC;
    try {
      scriptSRC = eval(wasmsrcurl);
    } catch (error) {
      scriptSRC = wasmsrcurl;
      console.log(`Error: ${error}. scriptSRC: ${scriptSRC}`);
    }
    script.src = scriptSRC;
    script.async = false;

    const existingScript = document.querySelector(`script[src="${scriptSRC}"]`);
    if (existingScript) {
      console.log("Script already loaded");
      return;
    }
    script.onload = () => {
      let qtLoader = undefined;
      var spinner = document.querySelector("#qtspinner");
      var canvas = document.querySelector("#screen");
      var status = document.querySelector("#qtstatus");

      qtLoader = new window.QtLoader({
        canvasElements: [canvas],
        restartMode: "DoNotRestart",
        stdoutEnabled: false,
        stderrEnabled: false,
        //containerElements: ["container-id"],
        showLoader: function (loaderStatus) {
          spinner.style.display = "block";
          canvas.style.display = "none";
          status.innerHTML = loaderStatus + "...";
        },
        showError: function (errorText) {
          status.innerHTML = errorText;
          spinner.style.display = "block";
          canvas.style.display = "none";
        },
        showExit: function () {
          status.innerHTML = "Application exit";
          if (qtLoader.exitCode !== undefined)
            status.innerHTML += " with code " + qtLoader.exitCode;
          if (qtLoader.exitText !== undefined)
            status.innerHTML += " (" + qtLoader.exitText + ")";
          spinner.style.position = "absolute";
          spinner.style.display = "block";
          canvas.style.display = "none";
        },
        showCanvas: function () {
          spinner.style.display = "none";
          canvas.style.position = "absolute";
          canvas.style.display = "block";
        },
      });
      var emscriptenSRC;
      try {
        emscriptenSRC = eval(wasmemscriptenstring);
      } catch (error) {
        emscriptenSRC = wasmemscriptenstring;
        console.log(`Error: ${error}. emscriptenSRC: ${emscriptenSRC}`);
      }
      qtLoader.loadEmscriptenModule(emscriptenSRC);
      window.qtLoader = qtLoader;
      scriptLoaded.current = true;
      return qtLoader;
    };
    document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, [wasmsrcurl, wasmemscriptenstring]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <figure style={{ overflow: "hidden" }} id="qtspinner">
        <div
          style={{
            marginTop: "1.5em",
            lineHeight: "150%",
            textAlign: "center",
          }}
        >
          <img
            src="IdeaMapperLogo.svg"
            width="640"
            height="480"
            style={{ display: "block", margin: "auto" }}
            alt=""
          />
          <strong>Please wait while we set up IdeaMapper for you</strong>
          <div id="qtstatus"></div>
          <noscript>
            JavaScript is disabled. Please enable JavaScript to use this
            application.
          </noscript>
        </div>
      </figure>
      <div id="screen"></div>
    </div>
  );
};

export default React.memo(IdeaMapper);

/*import React, { useEffect } from "react";

const IdeaMapper = ({ wasmsrcurl, wasmemscriptenstring }) => {
  useEffect(() => {
    window.initWasm();
  }, [wasmsrcurl, wasmemscriptenstring]); // Add dependencies to re-run if these props change

  return (
    // <div style={{ width: "100%", height: "100%" }}>
    //   <figure style={{ overflow: "hidden" }} id="qtspinner">
    //     <div
    //       style={{
    //         marginTop: "1.5em",
    //         lineHeight: "150%",
    //         textAlign: "center",
    //       }}
    //     >
    //       <img
    //         src="IdeaMapperLogo.svg"
    //         width="640"
    //         height="480"
    //         style={{ display: "block", margin: "auto" }}
    //         alt=""
    //       />
    //       <strong>Please wait while we set up IdeaMapper for you</strong>
    //       <div id="qtstatus"></div>
    //       <noscript>
    //         JavaScript is disabled. Please enable JavaScript to use this
    //         application.
    //       </noscript>
    //     </div>
    //   </figure>
    //   <div id="screen"></div>
    <div></div>
  );
};

export default React.memo(IdeaMapper);*/
