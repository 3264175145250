/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   LoginComponent.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import "./LoginComponent.css";
import exchangeToken from "../../resources/exchangeToken";
import exchangeCode from "../../resources/exchangeCode";
import OauthPopup from "react-oauth-popup";
import "@aws-amplify/ui-react/styles.css";
import LogInWithClassLink from "../../images/classlink-logo.png";
import LogInWithClever from "../../images/LogInWithClever.png";
import IdeaMapperLogo from "../../images/IdeaMapperLogo.svg";
import AuthenticatorComponent from "./AuthenticatorComponent";
import { CheckboxField } from "@aws-amplify/ui-react";
import { isFirefox } from "mobile-device-detect";
import $ from "jquery";
import Cookie from "js-cookie";
//import DatePicker from "react-datepicker";#
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
// import "react-datepicker/dist/react-datepicker.css";
import { ageEnum, supportLinks } from "../../resources/enums";
import GoogleLoginButton from "./GoogleCustomLoginButton";

const LoginComponent = (props) => {
  const [hasChecked, setHasChecked] = useState(false);
  const [hasError, setHasError] = useState(true);
  const [validAge, setValidAge] = useState(ageEnum.unknown);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showOtherProviders, setShowOtherProviders] = useState(false);
  const [showHide, setShowHide] = useState(false);

  // ------------------------------------------------------------------------
  useEffect(() => {
    const isChecked = Cookie.get("agreedToPolicies") === "true";
    setHasChecked(isChecked);
    setHasError(!isChecked);
    const isValidAge = Cookie.get("ageVerified");
    if (isValidAge === undefined) {
      setValidAge(ageEnum.unknown);
    } else {
      setValidAge(isValidAge);
      //Cookie.set("agreedToPolicies", true, { expires: 365 });
    }
    async function verifyTokenAndProceed() {
      const verifyToken = sessionStorage.getItem("userServiceAccessToken");
      if (verifyToken) {
        try {
          const haveVerified = await props.checkExistingToken(verifyToken);
          if (haveVerified) {
            sessionStorage.setItem("otherProvider", true);
            window.saveDataForWASM(
              "google",
              "googleIdTokenString",
              "googleAccessTokenString",
              verifyToken
            );
            props.setShowSignInPage(false);
            return;
          } else {
            setShowHide(true);
            return;
          }
        } catch (error) {
          // Handle any errors that occur during the verification process
          console.error("Error verifying token:", error);
          return;
        }
      } else {
        setShowHide(true);
      }
    }
    verifyTokenAndProceed();
  }, []);

  // ------------------------------------------------------------------------
  useEffect(() => {
    manageDisabledLogInTooltip();

    // Clean up event listeners
    return () => {
      const disabledDiv = document.querySelector(".disabled-div");
      if (disabledDiv) {
        disabledDiv.removeEventListener("mouseover", handleMouseOver);
        disabledDiv.removeEventListener("mouseout", handleMouseOut);
      }
    };
  }, [hasError, hasChecked]);

  // ------------------------------------------------------------------------
  const manageDisabledLogInTooltip = () => {
    const disabledDiv = document.querySelector(".disabled-div");
    if (disabledDiv) {
      disabledDiv.addEventListener("mouseover", handleMouseOver);
      disabledDiv.addEventListener("mouseout", handleMouseOut);
    }
  };

  // ------------------------------------------------------------------------
  const handleMouseOver = () => {
    const myDiv = document.getElementById("disabled");
    if (hasError) {
      myDiv.title = "Please accept the terms below to proceed.";
    } else {
      myDiv.title = "";
    }
  };

  // ------------------------------------------------------------------------
  const handleMouseOut = () => {
    const myDiv = document.getElementById("disabled");
    myDiv.title = "";
  };
  // ------------------------------------------------------------------------
  // REMOVED FOR DEVELOPMENT
  /*// Microsoft Login - needs finisinh on azure side
  const azureSuccess = async (error, authData) => {
    // Needs to be ID token and need to see if we can get name, last name and email from AuthData
    const token = authData?.accessToken;
    const login = "azure";
    // exchange idToken for ideaMapperToken and catch any errors as soon as possible
    const { accessToken, name, lastName, email } = await exchangeUserToken(
      token,
      login
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
      return null;
    });
    if (accessToken !== null && accessToken !== undefined) {
      props.setShowSignInPage(false);
      window.saveDataForWASM(accessToken, email, name, lastName);
    }
  };
  */
  // ------------------------------------------------------------------------
  /*const onCodeClassLink = async (response) => {
    // Need to try and get below data from exchange - might need to do
    // exchange then exchangeToken, to get all data.
    const { accessToken } = await exchange(response, "classlink").catch(
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return null;
      }
    );
    if (accessToken !== null && accessToken !== undefined) {
      sessionStorage.setItem("otherProvider", true);
      window.saveDataForWASM("classlink", "classlink", accessToken);
      if (!window.hasWindowEventListenerWindow()) {
        window.addPageLeaveEventWindow();
      }
      props.setShowSignInPage(false);
    }
  };*/

  // ------------------------------------------------------------------------
  /* const onCloseClassLink = (response) => {};*/

  // ------------------------------------------------------------------------
  /*const onCodeClever = async (response) => {
    // Need to try and get below data from exchange - might need to do
    // exchange then exchangeToken, to get all data.
    const { accessToken } = await exchangeCode(response, "clever").catch(
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        return null;
      }
    );
    if (accessToken !== null && accessToken !== undefined) {
      sessionStorage.setItem("otherProvider", true);
      window.saveDataForWASM("clever", "clever", accessToken);
      props.setShowSignInPage(false);
    }
  };*/

  // ------------------------------------------------------------------------
  // Exhange AWS Cognito token for Ideamapper token
  const onCognitoSuccess = async (idToken) => {
    // Do await and catch errors within the await to get most data and
    // allow for better error handling depending on error
    const { userServiceToken } = await exchangeToken(
      idToken.signInUserSession.idToken.jwtToken,
      "cognito"
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    });
    if (userServiceToken !== null && userServiceToken !== undefined) {
      Cookie.set("agreedToPolicies", hasChecked, { expires: 365 });
      window.saveDataForWASM(
        "cognito",
        idToken.signInUserSession.idToken.jwtToken,
        "cognitoAccessTokenString",
        userServiceToken
      );
      props.setShowSignInPage(false);
    }
  };

  // ------------------------------------------------------------------------
  // Google Token exchange function after user identified themselves
  const onGoogleSuccess = async (credentialResponse) => {
    const { userServiceToken } = await exchangeToken(
      credentialResponse.access_token,
      "google"
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(`Error ${error}`);
      }
      console.log(`Error ${error}`);
    });
    if (userServiceToken !== null && userServiceToken !== undefined) {
      sessionStorage.setItem("otherProvider", true);
      Cookie.set("agreedToPolicies", hasChecked, { expires: 365 });
      sessionStorage.setItem("userServiceAccessToken", userServiceToken);
      window.saveDataForWASM(
        "google",
        "googleIdTokenString",
        credentialResponse.access_token,
        userServiceToken
      );
      props.setShowSignInPage(false);
    }
  };

  // ------------------------------------------------------------------------
  // Check whether the user is logged in or not and either show or hide the
  // the login modal
  const checkShouldDisplay = () => {
    return sessionStorage.getItem("userLoggedIn") ? "none" : "block";
  };

  // ------------------------------------------------------------------------
  // Function to change the state of "hasChecked" that is used for checkbox
  // checked boolean
  const handleCheckedChange = (checked) => {
    setHasChecked(checked);
    setHasError(!checked);
  };

  // ------------------------------------------------------------------------
  // Function that returns state of "hasChecked"
  const isChecked = () => hasChecked;

  // ------------------------------------------------------------------------
  // Function that returns state of "hasError"
  const isError = () => hasError;

  // ------------------------------------------------------------------------
  // Function that saves date selected by user
  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
  };

  // ------------------------------------------------------------------------
  // Function that saves valid age enum selected by user
  const handleValidAgeChange = (truefalse) => {
    setValidAge(truefalse);
  };

  // ------------------------------------------------------------------------
  // Age verifier function on submit with a cookie being added if user
  // entered correct age
  const verifyAge = () => {
    let currentDate = new Date();
    let birthdate = new Date(selectedDate);
    let diff = new Date(currentDate - birthdate);
    let age = Math.abs(diff.getUTCFullYear() - 1970);
    let isValid = age >= 13;
    let enums = ageEnum.unknown;
    if (isValid === true) {
      enums = ageEnum.valid;
    } else if (isValid === false) {
      enums = ageEnum.invalid;
    }

    Cookie.set("ageVerified", enums, { expires: 31536000, path: "/" });
    setValidAge(enums);
  };

  // ------------------------------------------------------------------------
  // Function to create a popup window
  const providerErrorPopup = (string) => {
    // Get the screen dimensions
    var screenW = window.screen.width;
    var screenH = window.screen.height;

    // Set the dimensions of the new window
    var winW = 640;
    var winH = 380;

    // Calculate the position for the new window to be centered
    var left = screenW / 2 - winW / 2;
    var top = screenH / 2 - winH / 2;

    // Open the new window
    var newWindow = window.open(
      "",
      "",
      `width=${winW},height=${winH},left=${left},top=${top}`
    );
    fetch(string)
      .then((response) => response.text())
      .then((data) => {
        newWindow.document.write(data);
      })
      .catch((error) => {
        console.error("Error fetching the HTML file:", error);
      });
  };

  // ------------------------------------------------------------------------
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "101%",
      height: "101%",
      maxWidth: "100%",
      transition: "all 5s ease",
      background: "rgba(195, 195, 195, 1)",
      display: `${checkShouldDisplay()}`,
    },
  };

  // ------------------------------------------------------------------------
  // Function to show/hide AWS Cognito/other providers for small screen users.
  const changeProvider = (show) => {
    setShowOtherProviders(show);
    if (show) {
      $(".amazon").attr("style", "display: none;");
      $(".column_3").attr("style", "display: flex;");
      $(".other_provider button").click($(this).blur());
    } else {
      $(".amazon").attr("style", "display: block;");
      $(".column_3").attr("style", "display: none;");
      $(".other_provider button").click($(this).blur());
    }
  };

  // ------------------------------------------------------------------------
  // Helper function to show/hide AWS Cognito if screen width is bigger than
  // 675px
  const handleScreenSize = () => {
    const screenWidth = window.screen.availWidth;
    if (screenWidth < 675) {
      $(".amazon").attr("style", "display: flex;");
      $(".column_3").attr("style", "display: none;");
      $(".other_provider").attr("style", "display: flex;");
    } else if (screenWidth >= 675) {
      $(".amazon").attr("style", "display: flex;");
      $(".column_3").attr("style", "display: flex;");
      $(".other_provider").attr("style", "display: none;");
      //
    }
  };

  // ------------------------------------------------------------------------

  // ------------------------------------------------------------------------
  useEffect(() => {
    handleScreenSize();

    const orientationHandle = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        // Landscape orientation
        alert("Please switch to portrait mode for the best experience.");
      }
    };
    window.addEventListener("resize", handleScreenSize);
    window.addEventListener("orientationchange", orientationHandle);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
      window.removeEventListener("orientationchange", orientationHandle);
    };
  }, []);

  // ------------------------------------------------------------------------
  return showHide ? (
    <div className="container">
      {props.configLoaded && props.loadFurther && (
        <div className="outer_container">
          {(validAge === ageEnum.invalid || validAge === ageEnum.unknown) && (
            <img src={IdeaMapperLogo} alt="" />
          )}
          {(validAge === ageEnum.valid || validAge === ageEnum.unknown) && (
            <div className="row_1">
              {validAge === ageEnum.valid && (
                <h3>Please sign in to use IdeaMapper</h3>
              )}
              {validAge === ageEnum.unknown && (
                <h3>Please enter your birth date</h3>
              )}
            </div>
          )}
          {validAge === ageEnum.unknown && (
            <div className="age_gate">
              <div className="row_age_1">
                <DatePicker
                  value={selectedDate}
                  maxDate={new Date()}
                  format="dd/MM/yyyy"
                  required={true}
                  clearAriaLabel="Clear value"
                  clearIcon={null}
                  maxDeta="century"
                  yearPlaceholder="yyyy"
                  monthPlaceholder="mm"
                  dayPlaceholder="dd"
                  onChange={(date) => {
                    handleSelectedDateChange(date);
                  }}
                />
              </div>
              <div className="row_age_2">
                <button
                  className="submit_button"
                  type="submit"
                  onClick={() => verifyAge()}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          {validAge === ageEnum.invalid && (
            <div className="row_2">
              <h3 className="age_invalid_msg">
                We appreciate your interest in our services. At this time, we're
                unable to proceed with your access request. This may be due to
                various reasons including eligibility criteria. We encourage you
                to review our{" "}
                <a
                  href=""
                  onClick={() => {
                    window.launchUrlPopup(supportLinks.termsOfService);
                  }}
                >
                  terms of service
                </a>{" "}
                for more information. Your understanding is greatly appreciated,
                and we hope to serve you in the future.
              </h3>
            </div>
          )}
          {validAge === ageEnum.unknown && (
            <div className="row_2">
              <p className="info">
                Your privacy and security are important to us. To comply with
                the Children's Online Privacy Protection Act (COPPA), we need to
                confirm your age before you can use our services. COPPA helps
                protect the online privacy and safety of individuals under the
                age of 13.
                <br />
                <br />
                By providing your birth date, you help us ensure that we're
                offering our services responsibly and in compliance with the
                law. We only use the information provided by you here to verify
                your age and will not use it for any other purposes.
                <br />
                <br />
                Thank you for your understanding and for helping us create a
                safe online environment for all users.
                <br />
                <br />
                For more information please view our{" "}
                <a
                  href=""
                  onClick={() => {
                    window.launchUrlPopup(supportLinks.termsOfService);
                  }}
                >
                  terms of service
                </a>{" "}
                ,{" "}
                <a
                  href=""
                  onClick={() => {
                    window.launchUrlPopup(supportLinks.cookiePolicy);
                  }}
                >
                  cookie policy
                </a>{" "}
                and{" "}
                <a
                  href=""
                  onClick={() => {
                    window.launchUrlPopup(supportLinks.privacyPolicy);
                  }}
                >
                  privacy policy
                </a>{" "}
                or{" "}
                <a
                  href=""
                  onClick={() => {
                    window.launchUrlPopup(supportLinks.support);
                  }}
                >
                  contact us
                </a>
                .
                <br />
                <br />
                Please note: ClassLink, Clever & Google Workspace users should
                login directly via their SSO provider and launch the app from
                there.
              </p>
            </div>
          )}
          {validAge === ageEnum.valid &&
            props.configLoaded &&
            props.loadFurther &&
            !isFirefox && (
              <>
                <div className="row_2">
                  <div
                    id="disabled"
                    className={`${isError() ? "disabled-div" : ""}`}
                    title=" "
                  >
                    <div
                      className={`signin_container ${
                        isError() ? "overlay_Gray" : ""
                      }`}
                    >
                      <div className="column_1">
                        <div className="amazon_container">
                          <AuthenticatorComponent
                            className="amazon"
                            passBack={onCognitoSuccess}
                            setShowSignInPage={props.setShowSignInPage}
                            removePageLeaveEvent={props.removePageLeaveEvent}
                          />
                        </div>
                      </div>
                      <div className="column_2">
                        <div className="splitter_container">
                          <div className="row_splitter_1"></div>
                          <div className="row_splitter_2">
                            <span>OR</span>
                          </div>
                          <div className="row_splitter_3"></div>
                        </div>
                        <button
                          className="other_provider"
                          onClick={() => changeProvider(!showOtherProviders)}
                        >
                          Other SSO Providers
                        </button>
                      </div>
                      <div className="column_3">
                        <div className="other_providers_container">
                          <div className="row_other_1">
                            <div className="google_container">
                              <GoogleLoginButton
                                onGoogleSuccess={onGoogleSuccess}
                              />
                            </div>
                          </div>
                          <div className="row_other_2">
                            <div className="clever_container">
                              <div
                                onClick={() =>
                                  providerErrorPopup("/cleverError.html")
                                }
                              >
                                <img src={LogInWithClever} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="row_other_3">
                            <div className="classlink_container">
                              <div
                                onClick={() =>
                                  providerErrorPopup("/classlinkError.html")
                                }
                              >
                                <img src={LogInWithClassLink} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row_3">
                  <div className="checkbox_container">
                    <CheckboxField
                      className="termsConditionsCheckbox"
                      checked={isChecked()}
                      hasError={isError()}
                      name="acknowledgement"
                      value="yes"
                      label={
                        <span>
                          I agree to the{" "}
                          <a
                            href=""
                            onClick={() =>
                              window.launchUrlPopup(supportLinks.gdpr)
                            }
                          >
                            GPDR Policy
                          </a>
                          ,{" "}
                          <a
                            href=""
                            onClick={() =>
                              window.launchUrlPopup(supportLinks.cookiePolicy)
                            }
                          >
                            Cookie Policy
                          </a>{" "}
                          and{" "}
                          <a
                            href=""
                            onClick={() =>
                              window.launchUrlPopup(supportLinks.termsOfService)
                            }
                          >
                            Terms of Service
                          </a>
                          .
                          <br />
                          (You must agree to these policies to continue.)
                        </span>
                      }
                      onChange={(e) => handleCheckedChange(e.target.checked)}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      )}
      {props.configLoaded && isFirefox && props.loadFurther && (
        <div className="signIn_container">
          <div className="signIn_main">
            <h2 className="block_browser">
              We apologize for any inconvenience.
              <br />
              IdeaMapper is currently not compatible with Mozilla Firefox at the
              moment. Please use a different web browser for uninterrupted
              access.
              <br />
              <br />
              We are actively working on a solution. {}
            </h2>
          </div>
        </div>
      )}
      {!props.loadFurther && (
        <div className="outer_container">
          <img src={IdeaMapperLogo} alt="" />
          <div className="row_2">
            <h3 className="close_tab_msg">
              We have detected that you have{" "}
              <span style={{ color: "#f87c56" }}>ideamapper</span> running on
              another tab. We've sent your map over there. <br />
              You can now close this tab.
            </h3>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default LoginComponent;
