/* -*- JS -*- */
// ------------------------------------------------------------------------
//
// This code is property of BioSilico Limited and is protected by
// copyright law and international treaties. Unauthorized
// reproduction or distribution of this file, or any portion of
// it may result in severe civil and criminal penalties, and will
// be prosecuted to the maximum extent possible under the law.
//
// = LIBRARY
//   HomepageJS
//
// = FILENAME
//   AuthenticatorComponent.jsx
//
// = AUTHOR
//   Who       When        Why/What
//   K. Plewa   09.11.2022  Development
//
// ------------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import "@aws-amplify/ui-react/styles.css";
import "./AuthenticatorComponent.css";
import { Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import $ from "jquery";
import Cookie from "js-cookie";
// ------------------------------------------------------------------------
// Create a separate component from Amplify Authenticator to prevent a render within a
// render in the LoginComponent that is caused by Amplify Authenticator
const AuthenticatorComponent = (props) => {
  //const [tokenExpiry, setTokenExpiry] = useState(null);
  const [myUser, setUser] = useState(null);
  //const [isSignInVisible, setIsSignInVisible] = useState(true);
  // ------------------------------------------------------------------------
  useEffect(() => {
    if (myUser !== null) {
      passDataToLogInComponent(myUser);
    }
  }, [myUser]); // This effect runs when 'user' state changes

  // ------------------------------------------------------------------------
  // POTENTIALY UNNECESSARY
  const authenticatorRemoveLeavePageListener = () => {
    if (myUser !== null) {
      $(".container").attr("style", "display: none;");
    }
    props.removePageLeaveEvent();
  };
  window.authenticatorRemoveLeavePageListener =
    authenticatorRemoveLeavePageListener;
  // ------------------------------------------------------------------------.
  // Pass the token email and users name and surname to main.js to be saved
  // and passed to wasm after it has been loaded
  const passDataToLogInComponent = (data) => {
    sessionStorage.setItem("userLoggedIn", true);
    props.passBack(data);
    //if (!window.hasWindowEventListenerWindow()) {
    // window.addPageLeaveEventWindow();
    //}
  };

  // ------------------------------------------------------------------------
  const signOut = async () => {
    try {
      console.log("Sign out");
      sessionStorage.removeItem("userLoggedIn");
      Cookie.remove("agreedToPolicies");
      Cookie.remove("ageVerified");
      await Auth.signOut();
      props.removePageLeaveEvent();
      return true;
    } catch (error) {
      //if (!window.hasWindowEventListenerWindow()) {
      //  window.addPageLeaveEventWindow();
      // }
      console.log("error signing out: ", error);
      return false;
    }
  };
  // Need to make the function global so the WASM can access it.
  window.signOut = signOut;
  // ------------------------------------------------------------------------
  const handleStateChange = () => {
    authenticatorRemoveLeavePageListener();
  };
  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your Email",
        labelHidden: true,
      },
      password: {
        placeholder: "Enter your Password",
        labelHidden: true,
      },
    },
    signUp: {
      given_name: {
        labelHidden: true,
        placeholder: "Enter your First Name",
        order: 1,
        isRequired: true,
      },
      family_name: {
        labelHidden: true,
        placeholder: "Enter your Family Name",
        order: 2,
        isRequired: true,
      },
      email: {
        labelHidden: true,
        placeholder: "Enter your Email",
        order: 3,
        isRequired: true,
      },
      password: {
        labelHidden: true,
        placeholder: "Enter your Password",
        order: 4,
        isRequired: true,
      },
      confirm_password: {
        labelHidden: true,
        placeholder: "Confirm your Password",
        order: 5,
        isRequired: true,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    forgotPassword: {
      username: {
        labelHidden: true,
      },
    },
  };
  return (
    <div>
      <Authenticator
        onStateChange={handleStateChange}
        formFields={formFields}
        className="amazon"
      >
        {({ user: newUser }) => {
          $(".signIn_main").attr("style", "display: none;");
          if (newUser && newUser.username && !myUser) {
            //setTokenExpiry(newUser.signInUserSession.idToken.payload.exp);
            setUser(newUser);
            passDataToLogInComponent(newUser);
            //setIsSignInVisible(false);
            props.setShowSignInPage(false);
          }
        }}
      </Authenticator>
    </div>
  );
};

export default AuthenticatorComponent;
// ------------------------------------------------------------------------
